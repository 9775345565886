import medal from "./assets/svg/medal.svg";
import pay from "./assets/svg/pay.svg";
import logistics from "./assets/svg/logistics.svg";
const availabitity = () => {
  //create random number between 10 and 100
  const random = Math.floor(Math.random() * 100) + 10;
  return random;
};
const FakeNames = () => {
  //create random name and surname and rating and reviews
  const names = [
    "John",
    "James",
    "Robert",
    "Michael",
    "William",
    "David",
    "Richard",
    "Charles",
    "Joseph",
    "Thomas",
    "Christopher",
    "Daniel",
    "Paul",
    "Mark",
    "Donald",
    "George",
    "Kenneth",
    "Steven",
    "Edward",
    "Brian",
    "Ronald",
    "Anthony",
    "Kevin",
    "Jason",
    "Matthew",
    "Gary",
    "Timothy",
    "Jose",
    "Larry",
    "Jeffrey",
    "Frank",
    "Scott",
    "Eric",
    "Stephen",
    "Andrew",
    "Raymond",
    "Gregory",
    "Joshua",
    "Jerry",
    "Dennis",
    "Walter",
    "Patrick",
    "Peter",
    "Harold",
    "Douglas",
    "Henry",
    "Carl",
    "Arthur",
    "Ryan",
    "Roger",
  ];
  const surnames = [
    "Smith",
    "Johnson",
    "Williams",
    "Jones",
    "Brown",
    "Davis",
    "Miller",
    "Wilson",
    "Moore",
    "Taylor",
    "Anderson",
    "Thomas",
    "Jackson",
    "White",
    "Harris",
    "Martin",
    "Thompson",
    "Garcia",
    "Martinez",
    "Robinson",
    "Clark",
    "Rodriguez",
    "Lewis",
    "Lee",
    "Walker",
    "Hall",
    "Allen",
    "Young",
    "Hernandez",
    "King",
    "Wright",
    "Lopez",
    "Hill",
    "Scott",
    "Green",
    "Adams",
    "Baker",
    "Gonzalez",
    "Nelson",
    "Carter",
    "Mitchell",
    "Perez",
    "Roberts",
    "Turner",
    "Phillips",
    "Campbell",
    "Parker",
    "Evans",
    "Edwards",
    "Collins",
    "Stewart",
    "Sanchez",
    "Morris",
    "Rogers",
    "Reed",
    "Cook",
    "Morgan",
    "Bell",
    "Murphy",
    "Bailey",
    "Rivera",
    "Cooper",
    "Richardson",
    "Cox",
    "Howard",
    "Ward",
    "Torres",
    "Peterson",
    "Gray",
    "Ramirez",
    "James",
    "Watson",
    "Brooks",
    "Kelly",
    "Sanders",
    "Price",
    "Bennett",
    "Wood",
    "Barnes",
    "Ross",
    "Henderson",
    "Coleman",
    "Jenkins",
    "Perry",
    "Powell",
    "Long",
    "Patterson",
    "Hughes",
    "Flores",
    "Washington",
    "Butler",
    "Simmons",
    "Foster",
    "Gonzales",
    "Bryant",
    "Alexander",
    "Russell",
    "Griffin",
    "Diaz",
    "Hayes",
  ];
  const reviews = [
    "Great product",
    "Good product",
    "Bad product",
    "Very good product",
    "Very bad product",
    "I love it",
    "I hate it",
    "I like it",
    "I don't like it",
    "I don't know",
  ];
  const randomName = names[Math.floor(Math.random() * names.length)];
  const randomSurname = surnames[Math.floor(Math.random() * surnames.length)];
  const randomRating = Math.floor(Math.random() * 5) + 1;

  const randomReviews = [...new Array(7)].map(
    (data) => reviews[Math.floor(Math.random() * reviews.length)]
  );

  return { randomName, randomSurname, randomRating, randomReviews };
};
const catagories = [
  "home",
  "Camera",
  "Laptops & Computers",
  "Electronics",
  "Smart Phones",
  "Watches",
  "Accessories",
];
const shopBy = [
  { name: "Camera", img: "/products/cancon.png" },
  { name: "Headphones", img: "/products/headphones.png" },
  { name: "Watches", img: "/products/rolex.png" },
  {
    name: "Laptops & Computers",
    img: "/products/laptop.png",
  },
  { name: "Drones", img: "/products/drone.png" },
  { name: "Electronics", img: "/products/samsungtv.png" },
  { name: "Smart Phones", img: "/products/m33.png" },
  { name: "smart watches", img: "/products/galaxywatch.png" },
  { name: "Accessories", img: "/products/BackPack.png" },
];
const TopCatagories = [
  "Smart Phones",
  "Laptops",
  "Watches",
  "Drones",
  "Camera",
];
const subCatagories: any = {
  "Laptops & Computers": {
    Laptops: ["Macbook", "Dell", "HP", "Lenovo", "Asus"],
    Desktops: ["Macbook", "Dell", "HP", "Lenovo", "Asus"],
  },
  Camera: {
    "DSLR Cameras": ["Canon", "Nikon", "Sony", "Fujifilm", "Panasonic"],
    "Mirrorless Cameras": ["Canon", "Nikon", "Sony", "Fujifilm", "Panasonic"],
    "Point & Shoot Cameras": [
      "Canon",
      "Nikon",
      "Sony",
      "Fujifilm",
      "Panasonic",
    ],
  },
  Electorincs: {
    "TV & Audio": ["TV", "Home Theater", "Speakers", "Headphones"],
    Drones: ["DJI", "Parrot", "Yuneec", "Autel", "GoPro"],
    "Camera & Photo": [
      "DSLR Cameras",
      "Mirrorless Cameras",
      "Point & Shoot Cameras",
    ],
    "Cell Phones": ["Smart Phones", "Unlocked Phones", "Prepaid Phones"],
    "Video Games": [
      "PlayStation 4",
      "Console x",
      "Nintendo Switch",
      "PC Gaming",
    ],
    "Wearable Technology": [
      "Smart Watches",
      "Fitness Trackers",
      "Virtual Reality",
    ],
    "Home Audio": ["Speakers", "Home Theater", "Headphones"],
    "Car Electronics & GPS": ["Car Stereos", "Car Speakers", "Car Video"],
    "Office Electronics": ["Printers", "Scanners", "Fax Machines"],
    "Portable Audio": [
      "MP3 & MP4 Players",
      "Headphones",
      "Bluetooth & Wireless Speakers",
    ],
  },
  "Smart Phones": {
    "Apple iPhone": [
      "iPhone 12",
      "iPhone 12 Pro",
      "iPhone 12 Pro Max",
      "iPhone 11",
      "iPhone 11 Pro",
      "iPhone 11 Pro Max",
      "iPhone XS",
      "iPhone XS Max",
      "iPhone XR",
      "iPhone X",
      "iPhone 8",
      "iPhone 8 Plus",
      "iPhone 7",
      "iPhone 7 Plus",
      "iPhone 6s",
      "iPhone 6s Plus",
      "iPhone SE",
    ],
    "Samsung Galaxy": [
      "Galaxy S21",
      "Galaxy S21+",
      "Galaxy S21 Ultra",
      "Galaxy S20",
      "Galaxy S20+",
      "Galaxy S20 Ultra",
      "Galaxy S10",
      "Galaxy S10+",
      "Galaxy S10e",
      "Galaxy Note20",
      "Galaxy Note20 Ultra",
      "Galaxy Note10",
      "Galaxy Note10+",
      "Galaxy Note9",
      "Galaxy Note8",
      "Galaxy S9",
      "Galaxy S9+",
      "Galaxy S8",
      "Galaxy S8+",
      "Galaxy S7",
      "Galaxy S7 Edge",
      "Galaxy S6",
      "Galaxy S6 Edge",
      "Galaxy S6 Edge+",
    ],
    "Google Pixel": [
      "Pixel 5",
      "Pixel 4a",
      "Pixel 4a 5G",
      "Pixel 4",
      "Pixel 4 XL",
      "Pixel 3a",
      "Pixel 3a XL",
      "Pixel 3",
      "Pixel 3 XL",
      "Pixel 2",
      "Pixel 2 XL",
      "Pixel",
      "Pixel XL",
    ],
  },
  Watches: {
    "smart watches": [
      "Apple Watch",
      "Samsung Galaxy Watch",
      "Fitbit",
      "Garmin",
      "Fossil",
      "Michael Kors",
      "Skagen",
      "Kate Spade",
      "Casio",
      "Suunto",
      "Mobvoi",
      "Withings",
      "Amazfit",
      "Huawei",
      "Garmin",
      "TomTom",
      "Polar",
      "Misfit",
      "Mobvoi",
      "Suunto",
      "Withings",
      "Amazfit",
      "Huawei",
      "Garmin",
      "TomTom",
      "Polar",
      "Misfit",
    ],
    "fitness trackers": [
      "Fitbit",
      "Garmin",
      "Apple Watch",
      "Samsung Galaxy Watch",
      "Fossil",
      "Michael Kors",
      "Skagen",
      "Kate Spade",
      "Casio",
      "Suunto",
      "Mobvoi",
      "Withings",
      "Amazfit",
      "Huawei",
      "Garmin",
      "TomTom",
      "Polar",
      "Misfit",
      "Mobvoi",
      "Suunto",
      "Withings",
      "Amazfit",
      "Huawei",
      "Garmin",
      "TomTom",
      "Polar",
      "Misfit",
    ],
    "activity trackers": [
      "Fitbit",
      "Garmin",
      "Apple Watch",
      "Samsung Galaxy Watch",
      "Fossil",
      "Michael Kors",
      "Skagen",
      "Kate Spade",
      "Casio",
      "Suunto",
      "Mobvoi",
      "Withings",
      "Amazfit",
      "Huawei",
      "Garmin",
      "TomTom",
      "Polar",
      "Misfit",
      "Mobvoi",
      "Suunto",
      "Withings",
      "Amazfit",
      "Huawei",
      "Garmin",
      "TomTom",
      "Polar",
      "Misfit",
    ],
    "smartwatch accessories": [
      "Apple Watch",
      "Samsung Galaxy Watch",
      "Fitbit",
      "Garmin",
      "Fossil",
      "Michael Kors",
      "Skagen",
      "Kate Spade",
      "Casio",
      "Suunto",
      "Mobvoi",
      "Withings",
      "Amazfit",
      "Huawei",
      "Garmin",
      "TomTom",
      "Polar",
      "Misfit",
      "Mobvoi",
      "Suunto",
      "Withings",
      "Amazfit",
      "Huawei",
      "Garmin",
      "TomTom",
      "Polar",
      "Misfit",
    ],
    "fitness tracker accessories": [
      "Fitbit",
      "Garmin",
      "Apple Watch",
      "Samsung Galaxy Watch",
      "Fossil",
      "Michael Kors",
      "Skagen",
      "Kate Spade",
      "Casio",
      "Suunto",
      "Mobvoi",
      "Withings",
      "Amazfit",
      "Huawei",
      "Garmin",
      "TomTom",
      "Polar",
      "Misfit",
      "Mobvoi",
      "Suunto",
      "Withings",
      "Amazfit",
      "Huawei",
      "Garmin",
      "TomTom",
      "Polar",
      "Misfit",
    ],
    "activity tracker accessories": [
      "Fitbit",
      "Garmin",
      "Apple Watch",
      "Samsung Galaxy Watch",
      "Fossil",
      "Michael Kors",
      "Skagen",
      "Kate Spade",
      "Casio",
      "Suunto",
      "Mobvoi",
      "Withings",
      "Amazfit",
      "Huawei",
      "Garmin",
      "TomTom",
      "Polar",
      "Misfit",
      "Mobvoi",
      "Suunto",
      "Withings",
      "Amazfit",
      "Huawei",
      "Garmin",
      "TomTom",
      "Polar",
      "Misfit",
    ],
  },
};
const Feautres = [
  {
    title: "Extended Warranty",
    subTitle: "One year warranty",
    icon: medal,
  },
  {
    title: "Fast Delivery",
    subTitle: "Secure and reliable",
    icon: logistics,
  },
  {
    title: "Secure Payment",
    subTitle: "Securely Encrypted",
    icon: pay,
  },
];
//Headphones //bag //power bank //rolex
const NonGb = ["Headphones", "bag", "power bank", "rolex"];
const choices = ["64 GB", "124 GB", "256 GB"];
const RandomFourColors = ["red", "blue", "green", "yellow"];

const Products = [
  {
    id: 1,
    title: "Galaxy M33 (4GB | 64 GB )",
    modals: "Galaxy M33",
    brand: "Samsung",
    price: 249,
    sale: 56,
    img: "/products/m33.png",
    images: ["/products/m33.png", "/products/m33.png", "/products/m33.png"],
    description:
      "Experience the power of the Galaxy M33 with its advanced features and stunning design.",
    specifications: [
      "4GB RAM",
      "64GB internal storage",
      "6.5-inch Super AMOLED display",
      "Triple camera setup (48MP+8MP+5MP)",
      "4000mAh battery with fast charging",
      "Android 11 operating system",
    ],
    categories: "Smart Phones",
    subCatagories: ["Samsung Galaxy"],
  },
  {
    id: 2,
    title: "Canon EOS Rebel T8i DSLR Camera",
    modals: "Canon EOS Rebel T8i",
    brand: "Canon",
    price: 899,
    sale: 90,
    img: "/products/cancon.png",
    images: [
      "/products/cancon.png",
      "/products/cancon.png",
      "/products/cancon.png",
    ],
    description:
      "Capture stunning photos and videos with the Canon EOS Rebel T8i DSLR camera.",
    specifications: [
      "24.1 megapixel APS-C CMOS sensor",
      "45-point all cross-type AF system",
      "Dual Pixel CMOS AF with Eye Detection AF",
      "4K video recording",
      "3.0-inch Vari-angle Touchscreen LCD",
      "Built-in Wi-Fi and Bluetooth",
    ],
    categories: "Camera",
    subCatagories: ["Canon", "DSLR Cameras"],
  },
  {
    id: 3,
    title: "MacBook Air M2 (8GB | 256GB SSD)",
    modals: "MacBook Air M2",
    brand: "Apple",
    price: 1099,
    sale: 0,
    img: "/products/macbook.png",
    images: [
      "/products/macbook.png",
      "/products/macbook.png",
      "/products/macbook.png",
    ],
    description: "Experience the power and portability of the MacBook Air M2.",
    specifications: [
      "Apple M2 chip with 8-core CPU and 8-core GPU",
      "8GB unified memory",
      "256GB SSD storage",
      "13.3-inch Retina display with True Tone",
      "Touch ID and Magic Keyboard",
      "Up to 18 hours of battery life",
    ],
    categories: "Laptops & Computers",
    subCatagories: ["Macbook", "Laptops"],
  },
  {
    id: 74,
    title: "Samsung 65-inch QLED 4K UHD TV",
    modals: "Samsung UHD TV",
    brand: "Samsung",
    price: 1499,
    sale: 90,
    img: "/products/samsungtv.png",
    images: [
      "/products/samsungtv.png",
      "/products/samsungtv2.png",
      "/products/samsungtv3.png",
    ],
    description:
      "Experience immersive entertainment with the Samsung 65-inch QLED 4K UHD TV.",
    specifications: [
      "4K UHD resolution with Quantum HDR 16X",
      "Direct Full Array 16X",
      "Ultra Viewing Angle with Anti-Glare",
      "Smart TV powered by Tizen",
      "Built-in Alexa and Google Assistant",
      "OneRemote with Voice Assistance",
    ],
    categories: "Electronics",
    subCatagories: ["TV", "TV & Audio"],
  },
  {
    id: 82,
    title: "Sony Alpha A7 III Mirrorless Camera",
    modals: "Sony Alpha A7 III",
    brand: "Sony",
    price: 1999,
    sale: 10,
    img: "/products/sony.png",
    images: ["/products/sony.png", "/products/sony.png", "/products/sony.png"],
    description:
      "Capture stunning photos and videos with the Sony Alpha A7 III mirrorless camera. Its full-frame sensor and advanced autofocus system deliver exceptional image quality.",
    specifications: [
      "24.2MP full-frame CMOS sensor",
      "693-point autofocus system",
      "4K HDR video recording",
      "5-axis image stabilization",
    ],
    categories: "Camera",
    subCatagories: ["Sony", "Mirrorless Cameras"],
  },
  {
    id: 93,
    title: "MacBook Air (M1, 2021)",
    modals: "MacBook Air",
    brand: "Apple",
    price: 999,
    sale: 0,
    img: "/products/macbook.png",
    images: [
      "/products/macbook.png",
      "/products/macbook.png",
      "/products/macbook.png",
      "/products/macbook.png",
    ],
    description:
      "Get the ultimate portable experience with the MacBook Air. The M1 chip delivers incredible performance and battery life, while the sleek design makes it easy to take on the go.",
    specifications: [
      "Apple M1 chip with 8-core CPU",
      "13.3-inch Retina display",
      "Up to 18 hours of battery life",
      "Touch ID fingerprint sensor",
    ],
    categories: "Laptops & Computers",
    subCatagories: ["Macbook", "Laptops"],
  },
  {
    id: 24,
    title: "Bose QuietComfort 35 II Headphones",
    modals: "Bose QuietComfort 35 II",
    brand: "Bose",
    price: 299,
    sale: 0,
    img: "/products/headphones.png",
    images: [
      "/products/headphones.png",
      "/products/headphones.png",
      "/products/headphones.png",
    ],
    description:
      "The Bose QuietComfort 35 II headphones are perfect for music lovers and frequent travelers. They feature advanced noise cancellation technology and up to 20 hours of battery life.",
    specifications: [
      "Advanced noise cancellation",
      "Bluetooth connectivity",
      "20 hours of battery life",
      "Alexa voice control",
    ],
    categories: "Electronics",
    subCatagories: ["Headphones", "Portable Audio", "Accessories", "Audio"],
  },
  {
    id: 14,
    title: "Samsung QLED 4K Smart TV",
    modals: "Smart TV",
    brand: "Samsung",

    price: 1399,
    sale: 10,
    img: "/products/samsungtv.png",
    images: [
      "/products/samsungtv.png",
      "/products/samsungtv2.png",
      "/products/samsungtv3.png",
    ],
    description:
      "Experience incredible picture quality with the Samsung QLED 4K Smart TV. With Quantum Dot technology and a 4K resolution, you'll feel like you're right in the action.",
    specifications: [
      "55-inch QLED display",
      "Quantum Dot technology",
      "Smart TV features with voice control",
      "4 HDMI ports",
    ],
    categories: "Electronics",
    subCatagories: ["TV", "TV & Audio"],
  },
  {
    id: 5,
    title: "iPhone 13 Pro (128GB)",
    modals: "iPhone 13 Pro",
    brand: "Apple",
    img: "/products/iphone13.png",
    images: [
      "/products/iphone131.png",
      "/products/iphone132.png",
      "/products/iphone133.png",
    ],
    price: 1099,
    sale: 0,
    description: "Experience the ultimate iPhone with the iPhone 13 Pro.",
    specifications: [
      "A15 Bionic chip with 6-core CPU and 5-core GPU",
      "128GB internal storage",
      "6.1-inch Super Retina XDR display",
      "Triple camera system (12MP Ultra Wide, 12MP Wide, and 12MP Telephoto)",
      "Ceramic Shield front cover",
    ],

    categories: "Smart Phones",
    subCatagories: ["iPhone", "Smart Phones"],
  },
  {
    id: 12,
    title: "DJI Mavic Air 2 Drone",
    modals: "DJI Mavic",
    brand: "DJI",
    price: 799,
    sale: 0,
    img: "/products/dji.png",
    images: ["/products/drone.png", "/products/dji1.png"],
    description:
      "The DJI Mavic Air 2 is a compact and powerful drone that offers high-quality video and photography capabilities. With its 48MP camera and 4K video resolution, you can capture stunning aerial footage with ease.",
    specifications: [
      "48MP camera with 4K video resolution",
      "3-axis gimbal stabilization",
      "34-minute flight time",
      "Up to 10km transmission range",
      "Obstacle sensing and avoidance",
      "Intelligent tracking and active control",
      "Compact and foldable design",
    ],
    categories: "Electronics",
    subCatagories: ["Camera", "DJI", "Drones"],
  },
  {
    id: 93,
    title: "Tumi Alpha 3 Backpack",
    modals: "Tumi Alpha 3",
    brand: "Tumi",
    price: 375,
    sale: 0,
    img: "/products/bagtumi.png",
    images: ["/products/bagtumi.png", "/products/tumibag2.png"],
    description:
      "The Tumi Alpha 3 Backpack is a stylish and functional bag that offers plenty of storage space for all your essentials. With its durable ballistic nylon construction and comfortable shoulder straps, you can carry your belongings with ease.",
    specifications: [
      "Durable ballistic nylon construction",
      "15-inch laptop compartment",
      "Tablet pocket",
      "Multiple interior and exterior pockets",
      "Adjustable shoulder straps",
      "Trolley sleeve for easy travel",
      "Dimensions: 17 x 14 x 9 inches",
    ],
    categories: "Accessories",
    subCatagories: ["Accessories", "Luggage", "Travel"],
  },
  {
    id: 198,
    title: "Drone X",
    modals: "Drone X",
    brand: "Drone X",
    price: 299.99,
    sale: 0,
    img: "/products/dronex.png",
    images: ["/products/dronex.png", "/products/dronex2.png"],
    description:
      "The Drone X is a powerful and easy-to-use drone that captures stunning aerial footage. With a sleek design and advanced features, it's perfect for both beginners and professionals.",
    specifications: [
      "1080p HD camera",
      "20-minute flight time",
      "GPS-enabled",
      "3-speed settings",
      "Auto takeoff/landing",
    ],
    categories: "Electronics",
    subCatagories: ["Camera", "Drones"],
  },
  {
    id: 192,

    title: "Backpack Pro",
    modals: "Backpack Pro",
    brand: "Backpack Pro",
    price: 79,
    sale: 20,
    img: "/products/Backpack.png",
    images: ["/products/Backpack.png", "/products/Backpack1.png"],
    description:
      "The Backpack Pro is a stylish and durable backpack that's perfect for travel, hiking, and everyday use. With plenty of pockets and compartments, it can hold all your essentials and more.",
    specifications: [
      "Water-resistant material",
      "Padded shoulder straps",
      "Laptop compartment",
      "Multiple pockets and compartments",
    ],
    categories: "Accessories",
    subCatagories: ["Accessories", "bag", "luggage", "travel"],
  },
  {
    id: 893,
    title: "Power Bank Ultra",
    modals: "Power Bank ",
    brand: "Power Ultra",
    price: 49.99,
    sale: 0,
    img: "/products/powerbank.png",
    images: ["/products/powerbank.png", "/products/powerbank.png"],
    description:
      "The Power Bank Ultra is a high-capacity portable charger that keeps your devices charged on the go. With fast charging technology and multiple USB ports, it's perfect for travel, camping, and emergencies.",
    specifications: [
      "20,000mAh battery capacity",
      "2 USB ports",
      "Fast charging technology",
      "LED power indicator",
      "Compact and lightweight",
    ],
    categories: "Accessories",
    subCatagories: ["Accessories", "power bank", "electronics"],
  },
  {
    id: 489,
    title: "Game Console X",
    modals: "Game",
    brand: "Console X",
    price: 399.99,
    sale: 0,
    img: "/products/xboxx.png",
    images: ["/products/xboxx.png", "/products/xbox2.png"],
    description:
      "The Game Console X is a powerful and versatile gaming console that lets you play your favorite games in stunning 4K resolution. With a massive library of games and advanced features, it's the ultimate gaming machine.",
    specifications: [
      "4K Ultra HD resolution",
      "1TB storage capacity",
      "Built-in Wi-Fi",
      "HDR support",
      "Multiple USB ports",
    ],
    categories: "Electronics",
    subCatagories: ["Console x", "Video Games"],
  },
  {
    id: 885,
    title: "Drone Pro",
    modals: "Drone",
    brand: "Dji ",
    price: 499.99,

    sale: 0,
    img: "/products/drone.png",
    images: ["/products/drone.png", "/products/drone2.png"],
    description:
      "The Drone Pro is a high-end drone that's designed for professional aerial photography and videography. With advanced features like obstacle avoidance and 4K camera, it's perfect for commercial use.",
    specifications: [
      "4K Ultra HD camera",
      "30-minute flight time",
      "Obstacle avoidance sensors",
      "GPS-enabled",
      "Multiple flight modes",
    ],
    categories: "Electronics",
    subCatagories: ["Camera", "Drones"],
  },
  {
    id: 79791,
    title: "Rolex Oyster Perpetual Datejust 41",
    modals: "Oyster",
    brand: "Rolex",
    price: 12000,
    sale: 0,
    img: "/products/rolex.png",
    images: ["/products/rolex.png", "/products/rolex2.png"],
    description:
      "The Rolex Oyster Perpetual Datejust 41 is a luxurious watch that combines classic design with modern features. It is crafted with the finest materials and is built to last. With its timeless style, this watch is perfect for any occasion.",
    specifications: [
      "Case Diameter: 41mm",
      "Movement: Automatic",
      "Water Resistance: 100m",
      "Material: Stainless Steel and Yellow Gold",
    ],
    categories: "Watches",
    subCatagories: ["Watches", "smart watches", "rolex"],
  },
  {
    id: 9872,
    title: "Apple Watch Series 7",
    modals: "Series 7",
    brand: "Apple",
    price: 399,
    sale: 0,
    img: "/products/applewatch.png",
    images: ["/products/applewatch.png", "/products/applewatch2.png"],
    description:
      "The Apple Watch Series 7 is the latest and most advanced smartwatch from Apple. It features a larger and brighter display, faster charging, and new watch faces. With its fitness and health tracking features, the Apple Watch Series 7 is perfect for anyone looking to improve their lifestyle.",
    specifications: [
      "Case Size: 41mm or 45mm",
      "Display: Always-On Retina Display",
      "Battery Life: Up to 18 hours",
      "Water Resistance: 50m",
    ],

    categories: "Watches",
    subCatagories: ["Watches", "smart watches"],
  },
  {
    id: 8973,
    title: "Rolex GMT-Master II",
    modals: "GMT-Master",
    brand: "Rolex",
    price: 18500,
    sale: 0,
    img: "/products/rolextype1.png",
    images: ["/products/rolextype1.png", "/products/rolextype2.png"],
    description:
      "The Rolex GMT-Master II is a classic watch that is perfect for travelers. It features a second time zone and a rotating 24-hour bezel. With its durable construction and elegant design, the Rolex GMT-Master II is a true masterpiece.",
    specifications: [
      "Case Diameter: 40mm",
      "Movement: Automatic",
      "Water Resistance: 100m",
      "Material: Stainless Steel and Ceramic",
    ],

    categories: "Watches",
    subCatagories: ["Watches", "rolex"],
  },
  {
    id: 9984,
    title: "Samsung Galaxy Watch4",
    modals: "Galaxy Watch4",
    brand: "Samsung",
    price: 249,
    sale: 0,
    img: "/products/galaxywatch.png",
    images: ["/products/galaxywatch.png", "/products/galaxywatch2.png"],
    description:
      "The Samsung Galaxy Watch4 is a feature-packed smartwatch that runs on Wear OS. It features an AMOLED display, fitness and health tracking, and support for Google Assistant. With its sleek design and advanced features, the Samsung Galaxy Watch4 is perfect for anyone looking for a high-end smartwatch.",
    specifications: [
      "Case Size: 40mm or 44mm",
      "Display: AMOLED",
      "Battery Life: Up to 40 hours",
      "Water Resistance: 50m",
    ],

    categories: "Watches",
    subCatagories: ["Watches", "smart watches"],
  },
  {
    id: 98773,
    title: "Google Pixel 6",
    modals: "Pixel 6",
    brand: "Google",
    price: 699,
    sale: 0,
    img: "/products/galaxyphone.png",
    images: ["/products/galaxyphone.png", "/products/galaxyphone2.png"],
    description:
      "Experience the latest from Google with the Pixel 6. This phone features a 6.4-inch AMOLED display and a powerful Tensor chip for fast and smooth performance.",
    specifications: [
      "6.4-inch AMOLED display",
      "Google Tensor chip",
      "8GB RAM",
      "128GB storage",
      "50MP + 12MP rear cameras",
      "8MP front camera",
      "4600mAh battery",
    ],
    categories: "Smart Phones",
    subCatagories: ["Samsung Galaxy"],
  },
  {
    id: 7987894,
    title: "OnePlus 9 Pro",
    modals: "9 Pro",
    brand: "OnePlus",
    price: 999,
    sale: 0,
    img: "/products/pixel.png",
    images: ["/products/pixel.png", "/products/pixel1.png"],
    description:
      "Get the ultimate flagship experience with the OnePlus 9 Pro. This phone features a 6.7-inch Fluid AMOLED display and a powerful Snapdragon 888 processor for fast and smooth performance.",
    specifications: [
      "6.7-inch Fluid AMOLED display",
      "Snapdragon 888 processor",
      "12GB RAM",
      "256GB storage",
      "48MP + 50MP + 8MP + 2MP rear cameras",
      "16MP front camera",
      "4500mAh battery",
    ],
    categories: "Smart Phones",
    subCatagories: ["Samsung Galaxy"],
  },
];
export {
  catagories,
  subCatagories,
  Products,
  shopBy,
  TopCatagories,
  Feautres,
  FakeNames,
  availabitity,
  NonGb,
  choices,
  RandomFourColors,
};
