import { combineReducers } from "redux";
import userReducer from "./userReducer";
const initialState: any = null;

const appReducers = combineReducers({
  userReducer,
});

const rootReducer = (state = initialState, action: any) => {
  return appReducers(state, action);
};

export default rootReducer;
