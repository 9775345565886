import React from "react";
import "./App.css";
import Search from "./component/search";
import "swiper/css";
import { ReactSVG } from "react-svg";
import menu from "./assets/svg/menu.svg";
import profile from "./assets/svg/userprofile.svg";
import Mask from "./assets/images/mask.png";
import callus from "./assets/images/callus.png";
import whatsapp from "./assets/images/whatsapp.png";
import information from "./assets/images/information.png";
import Z from "./assets/images/z.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logowhite from "./assets/images/logowhite.png";

import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  Link,
  useSearchParams,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import cart from "./assets/svg/Buycart.svg";
import logo from "./assets/svg/logo.png";
import arrowDown from "./assets/svg/arrowDown.svg";
import { colors } from "./utlis";
import SwiperComponent from "./component/swiper";
import loading from "./assets/svg/loading.svg";
import OfferCarousel from "./component/offers";

import loadingImage from "./assets/images/loading.gif";
import SuccussPng from "./assets/images/success.png";
import ErrorPng from "./assets/images/error.png";
import { SwiperSlide } from "swiper/react";
import EmptyStar from "./assets/svg/emptystar.svg";
import Star from "./assets/svg/start.svg";

import ScrollContainer from "react-indiana-drag-scroll";
import "animate.css";
import {
  catagories,
  Feautres,
  FakeNames,
  availabitity,
  Products,
  shopBy,
  subCatagories,
  TopCatagories,
  NonGb,
  choices,
} from "./data";
import { API_URL } from "./constants";
import fetchFunction from "./utlis/fetchFucntion";
import {
  CopyCartDisptact,
  setCartAction,
  setOrderAction,
  setTokenAction,
  setUpdate,
  setUserAction,
} from "./actions.ts/user";
import { useDispatch, useSelector } from "react-redux";
import trash from "./assets/svg/trash.svg";
import e from "express";
import userEvent from "@testing-library/user-event";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
function ScrollToTop({ children }: any) {
  const { pathname } = useLocation();

  React.useEffect(() => {
    const canControlScrollRestoration = "scrollRestoration" in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
}
const CardItem = ({ product, Confirm }: any) => {
  const [counter, setCounter] = React.useState(parseInt(product.quantity));
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch<any>();
  const CallApi = async (response: any, removeOne: any, remove: any) => {
    const token = localStorage.getItem("ecommerce-token");
    if (!token) {
      return;
    }
    const [res, err] = await fetchFunction({
      url: `${API_URL}addtousercard`,
      method: "POST",
      body: {
        product_ids: {
          ...response,
          ...(removeOne ? { removeOne: removeOne } : {}),
          ...(remove ? { remove: remove } : {}),
        },
      },
      auth: token,
    });

    if (err) {
      return;
    }
  };
  return (
    <div className="card-cart" style={{ marginTop: 30 }}>
      <Link to={`/product/${product.id}`} className="card-img-cart">
        <LazyLoadImage
          src={product.img}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          alt="product"
        />
      </Link>
      <div className="card-body-cart">
        <div className="card-title" style={{ width: "100%", maxWidth: 250 }}>
          <h3>{product.title}</h3>
        </div>{" "}
        <div
          className="product-Row-Counter"
          style={{
            marginTop: 10,
            width: "100%",
            background: "white",
            paddingBottom: 10,

            justifyContent: "center",
            minWidth: 75,
            maxWidth: 120,
          }}
        >
          <div className="product-Counter" style={{ gap: 10 }}>
            <div
              onClick={async () => {
                if (!loading) {
                  setLoading(true);
                  await CallApi(product, counter - 1, null);
                  dispatch(
                    setCartAction({
                      ...product,
                      ...{ add: null },
                    })
                  );
                  setCounter(counter - 1);
                  setLoading(false);
                }
              }}
              className="product-Counter-minus"
              style={{
                height: 30,
                width: 35,
                borderRadius: 15,
                border: "none",
                backgroundColor: "#008ECC",
                color: "white",
                cursor: "pointer",
              }}
            >
              {loading ? (
                <LazyLoadImage
                  src={loadingImage}
                  style={{ width: 20, height: 20 }}
                />
              ) : (
                "-"
              )}
            </div>
            <div
              className="product-Counter-number"
              style={{
                height: 30,
                border: "none",
                color: "#008ECC",
              }}
            >
              {counter}
            </div>
            <div
              onClick={async () => {
                if (!loading) {
                  setLoading(true);
                  await CallApi(product, null, null);
                  dispatch(
                    setCartAction({
                      ...product,
                      ...{ quantity: counter + 1, add: true },
                    })
                  );
                  setCounter(counter + 1);
                  setLoading(false);
                }
              }}
              className="product-Counter-minus"
              style={{
                height: 30,
                width: 35,
                borderRadius: 15,
                border: "none",
                backgroundColor: "#008ECC",
                color: "white",
                cursor: "pointer",
              }}
            >
              {loading ? (
                <LazyLoadImage
                  src={loadingImage}
                  style={{ width: 20, height: 20 }}
                />
              ) : (
                "+"
              )}
            </div>
          </div>
        </div>
        <div className="card-price" style={{ width: "100%", maxWidth: 80 }}>
          <h3>
            ${product.price - Math.round((product.price * product.sale) / 100)}{" "}
          </h3>
        </div>
        <div className="card-price" style={{ width: "100%", maxWidth: 80 }}>
          <h3>
            $
            {Math.round(
              (product.price - Math.round(product.price * product.sale) / 100) *
                counter
            )}{" "}
          </h3>
        </div>
        <div
          onClick={async () => {
            if (!loading) {
              setLoading(true);
              await CallApi(product, null, product.id);

              setLoading(false);
            }
          }}
          style={{ background: "white", cursor: "pointer" }}
        >
          {loading ? (
            <LazyLoadImage
              src={loadingImage}
              style={{ width: 20, height: 20 }}
            />
          ) : (
            <ReactSVG src={trash} style={{ height: 20, width: 20 }} />
          )}
        </div>
      </div>
    </div>
  );
};
const SmallCardChat = ({ product, multiPrice, Confirm }: any) => {
  const [counter, setCounter] = React.useState(parseInt(product.quantity));
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch<any>();
  const CallApi = async (response: any, removeOne: any, remove: any) => {
    const token = localStorage.getItem("ecommerce-token");
    if (!token) {
      return;
    }
    const [res, err] = await fetchFunction({
      url: `${API_URL}addtousercard`,
      method: "POST",
      body: {
        product_ids: {
          ...response,
          ...(removeOne ? { removeOne: removeOne } : {}),
          ...(remove ? { remove: remove } : {}),
        },
      },
      auth: token,
    });
    if (err) {
      return;
    }
  };
  return (
    <div className="card">
      <Link to={`/product/${product.id}`} className="card-img">
        <LazyLoadImage src={product.img} alt="product" />
      </Link>
      <div className="card-body">
        <div className="card-title">
          <h3
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {product.title}
          </h3>
        </div>
        <div className="card-price">
          <h3>
            {multiPrice ? "Price:  " : ""}$
            {product.price - Math.round((product.price * product.sale) / 100)}{" "}
            <span
              style={{
                ...{
                  textDecorationLine: multiPrice ? "none" : "line-through",
                },
                ...(multiPrice
                  ? { flexDirection: "column", display: "flex" }
                  : {}),
              }}
            >
              {multiPrice ? "Total:  " : ""}$
              {multiPrice
                ? Math.round(
                    (product.price -
                      Math.round(product.price * product.sale) / 100) *
                      counter
                  )
                : product.sale
                ? product.price
                : ""}
            </span>
          </h3>
        </div>
        {Confirm ? null : (
          <div
            onClick={async () => {
              if (!loading) {
                setLoading(true);
                await CallApi(product, null, product.id);

                setLoading(false);
              }
            }}
            className="absolut"
            style={{ background: "white", cursor: "pointer" }}
          >
            {loading ? (
              <LazyLoadImage
                src={loadingImage}
                style={{ width: 20, height: 20 }}
              />
            ) : (
              <ReactSVG src={trash} style={{ height: 20, width: 20 }} />
            )}
          </div>
        )}
        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
            width: "90%",
            height: 1,
            backgroundColor: "#F5F5F5",
            opacity: product.sale ? 1 : 0,
          }}
        />

        {Confirm ? (
          <div
            className="product-Row-Counter"
            style={{
              marginTop: 10,

              background: "white",
              paddingBottom: 10,
              width: "100%",
              textAlign: "left",
              fontFamily: "Poppins",
              justifyContent: "flex-start",
              paddingLeft: 10,
              fontSize: "0.8em",
            }}
          >
            Amout: {counter}
          </div>
        ) : (
          <div
            className="product-Row-Counter"
            style={{
              marginTop: -10,

              background: "white",
              paddingBottom: 10,
              width: "100%",
              justifyContent: "center",
            }}
          >
            <div className="product-Counter">
              <div
                onClick={async () => {
                  if (!loading) {
                    setLoading(true);
                    await CallApi(product, counter - 1, null);
                    dispatch(
                      setCartAction({
                        ...product,
                        ...{ quantity: counter - 1, add: false },
                      })
                    );
                    setCounter(counter - 1);
                    setLoading(false);
                  }
                }}
                className="product-Counter-minus"
                style={{
                  height: 30,
                  cursor: "pointer",
                  color: "#008ECC",
                }}
              >
                {loading ? (
                  <LazyLoadImage
                    src={loadingImage}
                    style={{ width: 20, height: 20 }}
                  />
                ) : (
                  "-"
                )}
              </div>
              <div
                className="product-Counter-number"
                style={{
                  height: 30,
                  color: "#008ECC",
                }}
              >
                {counter}
              </div>
              <div
                onClick={async () => {
                  if (!loading) {
                    setLoading(true);
                    await CallApi(product, null, null);
                    dispatch(
                      setCartAction({
                        ...product,
                        ...{ quantity: counter + 1, add: true },
                      })
                    );
                    setCounter(counter + 1);
                    setLoading(false);
                  }
                }}
                className="product-Counter-minus"
                style={{
                  height: 30,
                  color: "#008ECC",
                  cursor: "pointer",
                }}
              >
                {loading ? (
                  <LazyLoadImage
                    src={loadingImage}
                    style={{ width: 20, height: 20 }}
                  />
                ) : (
                  "+"
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const SmallCard = ({ product }: any) => {
  return (
    <Link to={`/product/${product.id}`} className="card">
      <div className="card-img">
        <LazyLoadImage src={product.img} alt="product" />
      </div>
      <div className="card-body">
        <div className="card-title">
          <h3>{product.title}</h3>
        </div>
        <div className="card-price">
          <h3>
            ${product.price - Math.round((product.price * product.sale) / 100)}{" "}
            <span
              style={{
                textDecorationLine: "line-through",
              }}
            >
              {product.sale ? product.price : ""}
            </span>
          </h3>
        </div>
        <div
          className="absolut"
          style={{
            opacity: product.sale ? 1 : 0,
          }}
        >
          {product.sale}
        </div>
        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
            width: "90%",
            height: 1,
            backgroundColor: "#F5F5F5",
            opacity: product.sale ? 1 : 0,
          }}
        />
        <div
          className="card-price-save"
          style={{
            color: "#249B3E",
            opacity: product.sale ? 1 : 0,
          }}
        >
          Save - ${Math.round((product.price * product.sale) / 100)}
        </div>
      </div>
    </Link>
  );
};
const Card = ({ product }: any) => {
  return (
    <div className="card">
      <div className="card-img">
        <LazyLoadImage src={product.img} alt="product" />
      </div>
      <div className="card-body">
        <div className="card-title">
          <h3>{product.title}</h3>
        </div>
        <div className="card-price">
          <h3>{product.price}</h3>
        </div>
        <div className="card-description">
          <p>{product.description}</p>
        </div>
        <div className="card-specifications">
          <ul>
            {product.specifications.map((specification: any) => (
              <li>{specification}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
//
const HeaderTitle = ({ firstPart, secondPart }: any) => {
  return (
    <div
      style={{
        width: "90%",
        marginTop: 45,
        borderBottom: "0.5px solid #e6e6e6",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            borderBottom: "3px solid #008ECC",
            paddingBottom: 5,
            display: "flex",
            justifyContent: "flex-start",
            width: "fit-content",
            alignItems: "flex-start",
            alignSelf: "center",
          }}
        >
          <h4
            style={{
              fontSize: "1.2em",
              fontFamily: "Poppins",
              fontWeight: 700,
              letterSpacing: 0.3,
              color: "#666666",
            }}
          >
            {firstPart}{" "}
            <span
              style={{
                color: "#008ECC",
              }}
            >
              {secondPart}
            </span>
          </h4>
        </div>
        <div>
          <a
            style={{
              fontFamily: "Poppins",
              fontSize: "0.8em",
              color: "#008ECC",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 5,
            }}
          >
            View All
            <ReactSVG
              src={arrowDown}
              style={{ height: 15, width: 15, transform: "rotate(-90deg)" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};
const RelatedProduct = ({ selected }: any) => {
  return (
    <ScrollContainer
      style={{
        width: "90%",
        marginTop: 25,
        overflowX: "scroll",
        gap: 20,
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      {Products.map((product, key) => {
        return (
          <Link to={`/product/${product.id}`} className="card-related">
            <div className="card-img-related">
              <LazyLoadImage src={product.img} alt="product" />
            </div>
            <div className="card-body-related">
              <div className="card-title-related">
                <h3>{product.title}</h3>
              </div>
              <div className="card-price-related">
                <h3>${product.price}</h3>
              </div>
            </div>
          </Link>
        );
      })}
    </ScrollContainer>
  );
};
const CardPlace = ({ Items, setTopProducts, topProduct }: any) => {
  return (
    <div
      style={
        getWindowDimensions().width > 600
          ? {
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 45,
              gap: 10,
            }
          : {
              flexDirection: "column",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 45,
              gap: 10,
            }
      }
    >
      <div>
        <div
          style={
            getWindowDimensions().width > 600
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: 250,
                  border: "1.5px solid #e6e6e6",
                  borderRadius: 15,
                  height: 250,
                  gap: 10,
                }
              : {
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "revert",
                  width: "100%",
                  borderRadius: 15,
                  padding: 10,
                  gap: 20,
                  overflowX: "scroll",
                }
          }
        >
          {[...["All"], ...TopCatagories].map((data, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setTopProducts(data);
                }}
              >
                <h6
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "0.9em",
                    color: topProduct === data ? "#008ECC" : "black",
                    fontWeight: topProduct === data ? 600 : 400,
                    letterSpacing: 0.3,
                  }}
                >
                  {data}
                </h6>
              </div>
            );
          })}
        </div>
      </div>
      <div className="CardFlex">
        {Items.map((data: any, index: number) => {
          return <SmallCard product={data} key={index} />;
        })}
      </div>
    </div>
  );
};

const FeaturesCarouse = () => {
  if (getWindowDimensions().width > 600) {
    return (
      <div
        style={{
          width: "70%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 25,
        }}
      >
        {Feautres.map((data: any, index: number) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                border: " 0.5px solid #C0C5C0",
                paddingTop: 17,
                paddingBottom: 17,
                width: "30%",
                borderRadius: 3,
                gap: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactSVG
                src={data.icon}
                style={{
                  height: 40,
                  width: 40,
                }}
              />
              <div>
                <h4
                  style={{
                    fontFamily: "POPPINS",
                    fontWeight: 700,
                    fontSize: "0.8em",
                    letterSpacing: 0.3,
                  }}
                >
                  {data.title}
                </h4>
                <h5
                  style={{
                    fontFamily: "POPPINS",
                    fontSize: "0.6em",
                    fontWeight: 400,
                  }}
                >
                  {data.subTitle}
                </h5>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        overflowX: "scroll",
        justifyContent: "flex-start",
        gap: 10,
        alignItems: "center",
        marginTop: 25,
      }}
    >
      {Feautres.map((data: any, index: number) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              border: " 0.5px solid #C0C5C0",
              paddingTop: 10,
              paddingBottom: 10,
              width: 200,
              minWidth: 200,
              borderRadius: 3,
              gap: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ReactSVG
              src={data.icon}
              style={{
                height: 30,
                width: 30,
              }}
            />
            <div>
              <h4
                style={{
                  fontFamily: "POPPINS",
                  fontWeight: 700,
                  fontSize: 12,
                  letterSpacing: 0.3,
                }}
              >
                {data.title}
              </h4>
              <h5
                style={{
                  fontFamily: "POPPINS",
                  fontWeight: 400,
                  fontSize: 10,
                }}
              >
                {data.subTitle}
              </h5>
            </div>
          </div>
        );
      })}
    </div>
  );
};
const Header = () => {
  const selector = useSelector((state: any) => state.userReducer);
  const navigate = useNavigate();

  const username =
    selector.user && selector.user.username ? selector.user.username : null;
  const [show, setShow] = React.useState(false);
  const [selected, setSelected] = React.useState("Home");

  if (getWindowDimensions().width > 600) {
    return (
      <div
        style={{
          width: "90%",
          height: 70,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          paddingBottom: 10,
          borderBottom: "0.5px solid #e6e6e6",
        }}
      >
        <div
          style={{
            width: "30%",
            display: "flex",
            justifyContent: "flex-start",
            cursor: "pointer",
            alignItems: "flex-end",
            gap: 40,
          }}
        >
          <ReactSVG
            onClick={() => {
              setShow(true);
            }}
            src={menu}
            style={{
              height: 38,
              width: 38,
            }}
          />
          {show ? (
            <div
              style={{
                width: "100%",
                maxWidth: 400,
                position: "fixed",
                zIndex: 100,
                left: 0,
                top: 0,
                height: "100vh",
                backgroundColor: "#F3F9FB",
                paddingTop: 50,
                borderRight: "0.5px solid #e6e6e6",
              }}
            >
              <div
                onClick={() => {
                  setShow(false);
                }}
                style={{
                  width: "100%",
                  textAlign: "right",
                  padding: 10,
                  fontFamily: "Poppins",
                  fontWeight: 900,
                  fontSize: "1.2em",
                }}
              >
                x
              </div>

              {catagories.map((data: string, index: number) => {
                return (
                  <Link
                    onClick={() => {
                      setSelected(data);
                      setShow(false);
                    }}
                    to={data === "home" ? "/" : `/catgory/${data}`}
                    key={index}
                    style={{
                      padding: 5,
                      fontFamily: "Poppins",
                      whiteSpace: "nowrap",
                      fontSize: "0.8em",
                      paddingLeft: 15,
                      paddingRight: 15,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 10,
                      borderRadius: 20,
                      backgroundColor:
                        selected === data ? colors.lightblue : "#F3F9FB",
                      color: selected === data ? "white" : "black",
                      position: "relative",
                    }}
                  >
                    {data}
                    {subCatagories[data] ? (
                      <span>
                        <ReactSVG
                          src={arrowDown}
                          style={{ height: 15, width: 15, marginTop: 2 }}
                        />
                      </span>
                    ) : null}
                  </Link>
                );
              })}
            </div>
          ) : null}

          <Link to="/">
            <LazyLoadImage
              src={logo}
              alt="logo"
              style={{
                objectFit: "contain",
                height: 35,
              }}
            />
          </Link>
        </div>

        <div
          style={{
            width: "70%",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "60%",
              marginLeft: "10%",
            }}
          >
            <Search />
          </div>
          <div
            style={{
              width: "25%",
              paddingLeft: "3%",
              display: "flex",
              alignItems: "center",
              gap: 10,
              justifyContent: "space-around",
            }}
          >
            <div
              onClick={() => {
                console.log(selector.user);
                if (selector.user.username && selector.user.user_id) {
                  navigate("/profile");
                } else {
                  navigate("/login");
                }
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "center",
              }}
            >
              <ReactSVG
                src={profile}
                style={{
                  height: 20,
                  width: 20,
                }}
              />
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.7em",
                  cursor: "pointer",
                  color: "#666666",
                  fontWeight: 800,
                }}
              >
                {username || "Sign Up/Sign in"}
              </p>
            </div>
            <div
              style={{
                width: 1.3,
                height: "50%",
                background: "#e6e6e6",
              }}
            />
            <Link
              to="/cart"
              style={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                justifyContent: "center",
                position: "relative",
              }}
            >
              {selector.cart && selector.cart.length > 0 ? (
                <div
                  style={{
                    width: 15,
                    height: 15,
                    position: "absolute",
                    top: -5,
                    left: -5,
                    borderRadius: "50%",
                    background: "#FF5C00",
                    fontSize: 10,
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                    fontWeight: 800,
                  }}
                >
                  {selector.cart && selector.cart.length}
                </div>
              ) : null}
              <ReactSVG
                src={cart}
                style={{
                  height: 20,
                  width: 20,
                }}
              />
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.7em",
                  fontWeight: 800,
                  color: "#666666",
                }}
              >
                Cart
              </p>
            </Link>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        width: "90%",
        paddingBottom: 10,
        borderBottom: "0.5px solid #e6e6e6",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 70,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            gap: 20,
          }}
        >
          <ReactSVG
            onClick={() => {
              setShow(true);
            }}
            src={menu}
            style={{
              height: 38,
              width: 38,
            }}
          />
          {show ? (
            <div
              style={{
                width: "100%",
                maxWidth: 400,
                position: "fixed",
                zIndex: 100,
                left: 0,
                top: 0,
                height: "100vh",
                backgroundColor: "#F3F9FB",
                paddingTop: 50,
                borderRight: "0.5px solid #e6e6e6",
              }}
            >
              <div
                onClick={() => {
                  setShow(false);
                }}
                style={{
                  width: "100%",
                  textAlign: "right",
                  padding: 10,
                  fontFamily: "Poppins",
                  fontWeight: 900,
                  fontSize: "1.2em",
                }}
              >
                x
              </div>

              {catagories.map((data: string, index: number) => {
                return (
                  <Link
                    onClick={() => {
                      setSelected(data);
                      setShow(false);
                    }}
                    to={data === "home" ? "/" : `/catgory/${data}`}
                    key={index}
                    style={{
                      padding: 5,
                      fontFamily: "Poppins",
                      whiteSpace: "nowrap",
                      fontSize: "0.8em",
                      paddingLeft: 15,
                      paddingRight: 15,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 10,
                      borderRadius: 20,
                      backgroundColor:
                        selected === data ? colors.lightblue : "#F3F9FB",
                      color: selected === data ? "white" : "black",
                      position: "relative",
                    }}
                  >
                    {data}
                    {subCatagories[data] ? (
                      <span>
                        <ReactSVG
                          src={arrowDown}
                          style={{ height: 15, width: 15, marginTop: 2 }}
                        />
                      </span>
                    ) : null}
                  </Link>
                );
              })}
            </div>
          ) : null}
          <Link to="/">
            <LazyLoadImage
              src={logo}
              alt="logo"
              style={{
                objectFit: "contain",
                height: 25,
              }}
            />
          </Link>
        </div>

        <div
          style={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              justifyContent: "space-around",
            }}
          >
            <div
              onClick={() => {
                console.log(selector.user);
                if (selector.user.username && selector.user.user_id) {
                  navigate("/profile");
                } else {
                  navigate("/login");
                }
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "center",
              }}
            >
              <ReactSVG
                src={profile}
                style={{
                  height: 20,
                  width: 20,
                }}
              />
            </div>
            <div
              style={{
                width: 1.3,
                height: "50%",
                background: "#e6e6e6",
              }}
            />
            <Link
              to="/cart"
              style={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                justifyContent: "center",
                position: "relative",
              }}
            >
              {selector.cart && selector.cart.length > 0 ? (
                <div
                  style={{
                    width: 15,
                    height: 15,
                    position: "absolute",
                    top: -5,
                    left: -5,
                    borderRadius: "50%",
                    background: "#FF5C00",
                    fontSize: 10,
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                    fontWeight: 800,
                  }}
                >
                  {selector.cart && selector.cart.length}
                </div>
              ) : null}
              <ReactSVG
                src={cart}
                style={{
                  height: 20,
                  width: 20,
                }}
              />
            </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: 20,
        }}
      >
        <Search />
      </div>
    </div>
  );
};
const CatagoryPlace = ({ setSelected, selected }: any) => {
  const [visible, setVisible] = React.useState<any>(null);
  return (
    <ScrollContainer
      style={{
        width: "90%",
        overflowX: "scroll",
        borderBottom: "0.5px solid #e6e6e6",
        display: "flex",
        paddingTop: 15,
        paddingBottom: 15,
        gap: 10,
        alignItems: "center",
      }}
    >
      {catagories.map((data: string, index: number) => {
        return (
          <Link
            onClick={() => {
              setSelected(data);
              setVisible(data);
            }}
            to={data === "home" ? "/" : `/catgory/${data}`}
            key={index}
            style={{
              padding: 5,
              fontFamily: "Poppins",
              whiteSpace: "nowrap",
              fontSize: "0.8em",
              paddingLeft: 15,
              paddingRight: 15,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 10,
              borderRadius: 20,
              backgroundColor: selected === data ? colors.lightblue : "#F3F9FB",
              color: selected === data ? "white" : "black",
              position: "relative",
            }}
          >
            {data}
            {subCatagories[data] ? (
              <span>
                <ReactSVG
                  src={arrowDown}
                  style={{ height: 15, width: 15, marginTop: 2 }}
                />
              </span>
            ) : null}
            {visible === "data" ? (
              <div
                style={{
                  position: "absolute",
                  top: 30,
                  zIndex: 100,
                  background: "white",
                }}
              >
                {Object.keys(subCatagories[data]).map(
                  (data: string, index: number) => {
                    console.log(data);
                    return <div>{data}</div>;
                  }
                )}
              </div>
            ) : null}
          </Link>
        );
      })}
    </ScrollContainer>
  );
};
function Produc() {
  const [selected, setSelected] = React.useState("home");

  const [topProduct, setTopProducts] = React.useState("Smart Phones");
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  let { id } = useParams();
  const dispatch = useDispatch<any>();
  const Items = Products.find((data) => String(data.id) === String(id));
  const DoesItHave = Items?.subCatagories?.filter((data) =>
    NonGb.includes(data)
  );
  const [Rating] = React.useState(FakeNames().randomRating);
  const [counter, setCounter] = React.useState(1);
  const [selectedItem, setSelectedItem] = React.useState("description");
  const [loading, SetLoading] = React.useState(false);
  const selector = useSelector((state: any) => state.userReducer);
  const [Itemloading, setItemLoading] = React.useState(false);
  const [Images, SetImages] = React.useState<any>(null);
  const navigate = useNavigate();
  if (!Items) {
    return <div>Not Found</div>;
  }
  const CallApi = async (response: any) => {
    const token = localStorage.getItem("ecommerce-token");
    if (!token) {
      return;
    }
    const [res, err] = await fetchFunction({
      url: `${API_URL}addtousercard`,
      method: "POST",
      body: { product_ids: response },
      auth: token,
    });
    if (err) {
      return;
    }
  };
  const BuyNowFunction = async () => {
    if (selector.user.user_id) {
      SetLoading(true);
      await CallApi({ ...Items, ...{ quantity: counter, add: true } });
      dispatch(
        setCartAction({ ...Items, ...{ quantity: counter, add: true } })
      );
      SetLoading(false);
      navigate("/cart");
    } else {
      dispatch(
        setCartAction({ ...Items, ...{ quantity: counter, add: true } })
      );
      navigate("/login?page=cart");
    }
  };

  const AddToCartFunction = async () => {
    setItemLoading(true);
    await CallApi({ ...Items, ...{ quantity: counter, add: true } });
    dispatch(setUpdate({ status: true, message: "Product Added To Cart" }));
    dispatch(setCartAction({ ...Items, ...{ quantity: counter, add: true } }));
    setItemLoading(false);
  };

  return (
    <div className="App">
      <Header />
      <CatagoryPlace {...{ setSelected, selected }} />
      <ScrollToTop />
      <div
        style={{
          width: "90%",
          height: 90,
          borderRadius: 10,
          fontSize: "1.3em",
          fontFamily: "Poppins",
          fontWeight: 700,
          letterSpacing: 1,
          marginTop: 40,
          display: "flex",
          color: "#008ECC",
          justifyContent: "center",
          alignItems: "center",
          background: "#F5F5F5",
        }}
      >
        Product Details
      </div>
      <div
        style={{
          width: "90%",
          marginLeft: "5%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="Product-container">
          <div className="product-item-class">
            <div className="MainImage">
              <div
                style={{
                  width: "100%",
                  background: "#F5F5F5",
                  minHeight: 350,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LazyLoadImage
                  src={Images !== null ? Items?.images[Images] : Items.img}
                />
              </div>
            </div>
            <div className="carousel">
              {Items?.images?.map((data, index) => {
                return (
                  <div
                    onClick={() => {
                      SetImages(index);
                    }}
                    className="carousel-item"
                    style={{
                      border: Images === index ? "2px solid #008ECC" : "none",
                    }}
                  >
                    <LazyLoadImage className="carousel-item-image" src={data} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="product-item">
            <h3>Modal {Items?.modals}</h3>
            <h3>Brand: {Items?.brand}</h3>
            <p>Items in stock: {availabitity()}</p>
            <h1
              style={{
                fontWeight: 800,
                paddingTop: 10,
              }}
            >
              {Items?.title}
            </h1>
            <div className="Rating">
              {[...new Array(5)].map((data, index) => {
                return (
                  <ReactSVG
                    src={Rating >= index ? Star : EmptyStar}
                    key={index}
                    style={{ width: 15, height: 15 }}
                  />
                );
              })}
              <span> {Rating}</span>
            </div>
            <ul className="product-specification">
              {Items?.specifications?.map((data) => {
                return <li className="product-specification-item">{data}</li>;
              })}
            </ul>
            {DoesItHave && DoesItHave.length < 1 ? (
              <div className="product-choices">
                {choices.map((data, index) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedIndex(index);
                      }}
                      className="product-choices-item"
                      style={{
                        color: selectedIndex === index ? "#008ECC" : "black",
                        border:
                          selectedIndex === index
                            ? "1px solid #008ECC"
                            : "none",
                      }}
                    >
                      {data}
                    </div>
                  );
                })}
              </div>
            ) : null}

            <div className="card-price-product">
              <h3 style={{ fontSize: "0.8em" }}>USD(incl. of all taxes)</h3>
              <h3>
                $
                {Items &&
                  Items?.price -
                    Math.round((Items.price * Items.sale) / 100)}{" "}
                <span
                  style={{
                    textDecorationLine: "line-through",
                  }}
                >
                  {Items?.sale ? Items.price : ""}
                </span>
              </h3>
            </div>
            <div className="product-Row-Counter">
              <div className="product-Counter">
                <div
                  onClick={() => {
                    setCounter(counter - 1);
                  }}
                  className="product-Counter-minus"
                >
                  -
                </div>
                <div className="product-Counter-number">{counter}</div>
                <div
                  onClick={() => {
                    setCounter(counter + 1);
                  }}
                  className="product-Counter-minus"
                >
                  +
                </div>
              </div>
              <div className="product-row-buy">
                <div
                  onClick={() => {
                    if (!loading) {
                      BuyNowFunction();
                    }
                  }}
                  className="product-row-buy-choice"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {loading ? (
                    <LazyLoadImage
                      src={loadingImage}
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                  ) : (
                    "Buy Now"
                  )}
                </div>
                <div
                  className="product-row-buy-choice"
                  onClick={() => {
                    if (!Itemloading) {
                      AddToCartFunction();
                    }
                  }}
                  style={{
                    background: "white",
                    cursor: "pointer",

                    border: "1px solid #008ECC",
                    color: "#008ECC",
                  }}
                >
                  {Itemloading ? (
                    <LazyLoadImage
                      src={loadingImage}
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                  ) : (
                    "Add to cart"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-headers" style={{ position: "relative" }}>
          <div
            onClick={() => {
              //  description//specifications
              setSelectedItem("description");
            }}
            className="product-headers-item"
            style={{
              color: selectedItem === "description" ? "#008ECC" : "black",
            }}
          >
            Description
            {selectedItem === "description" ? (
              <div
                style={{
                  width: 80,
                  position: "absolute",
                  bottom: -2.5,
                  height: 4,
                  borderRadius: 1,
                  background: "#008ECC",
                }}
              />
            ) : null}
          </div>

          <div
            onClick={() => {
              setSelectedItem("specification");
            }}
            className="product-headers-item"
            style={{
              color: selectedItem === "specification" ? "#008ECC" : "black",
            }}
          >
            Specification{" "}
            {selectedItem === "specification" ? (
              <div
                style={{
                  width: 80,
                  position: "absolute",
                  bottom: -2.5,
                  height: 4,
                  borderRadius: 1,
                  background: "#008ECC",
                }}
              />
            ) : null}
          </div>

          <div
            onClick={() => {
              setSelectedItem("reviews");
            }}
            className="product-headers-item"
            style={{
              color: selectedItem === "reviews" ? "#008ECC" : "black",
            }}
          >
            Reviews
            {selectedItem === "reviews" ? (
              <div
                style={{
                  width: 80,
                  position: "absolute",
                  bottom: -2.5,
                  height: 4,
                  borderRadius: 1,
                  background: "#008ECC",
                }}
              />
            ) : null}
          </div>
        </div>
        <div className="descriptContainers">
          {selectedItem === "description" ? (
            <div className="descriptonpStyle">{Items.description}</div>
          ) : null}

          {selectedItem === "specification" ? (
            <ul className="product-specification">
              {Items?.specifications?.map((data) => {
                return <li className="product-specification-item">{data}</li>;
              })}
            </ul>
          ) : null}
        </div>
        {selectedItem === "reviews" ? (
          <div className="Reviews">
            {FakeNames().randomReviews.map((data) => (
              <div className="ReviewRow">
                <div className="NameReview">
                  {FakeNames().randomName} {FakeNames().randomSurname}
                </div>
                <div className="NameComment">{data}</div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <HeaderTitle firstPart={"Recently"} secondPart={"Viewed"} />
      <RelatedProduct />
      <div className="WihtePsace" />
    </div>
  );
}
function Catagory() {
  const [selected, setSelected] = React.useState("home");

  const [topProduct, setTopProducts] = React.useState("Smart Phones");
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  let { id } = useParams();
  const Items = Products.filter((data) => {
    return data.categories === id || (id && data.subCatagories.includes(id));
  });
  if (Items.length < 1) {
    return <div>Not Found</div>;
  }

  return (
    <div className="App">
      <Header />
      <CatagoryPlace {...{ setSelected, selected }} />
      <ScrollToTop />
      <div
        style={{
          width: "90%",
          height: 90,
          borderRadius: 10,
          fontSize: "1.3em",
          fontFamily: "Poppins",
          fontWeight: 700,
          letterSpacing: 1,
          marginTop: 40,
          display: "flex",
          color: "#008ECC",
          justifyContent: "center",
          alignItems: "center",
          background: "#F5F5F5",
        }}
      >
        {Items.length} Products in {id}
      </div>
      <div
        style={{
          width: "90%",
          marginLeft: "5%",
          display: "flex",
          marginTop: 40,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="CardFlex"
          style={{
            width: "100%",
          }}
        >
          {Items.map((data: any, index: number) => {
            return <SmallCard product={data} key={index} />;
          })}
        </div>
      </div>
      <HeaderTitle firstPart={"Recently"} secondPart={"Viewed"} />
      <RelatedProduct />
      <div className="WihtePsace" />
    </div>
  );
}

function Login() {
  const [selected, setSelected] = React.useState("home");
  const [isSignIn, setSignIn] = React.useState(true);
  const [email_address, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any>(null);
  const [success, setSuccess] = React.useState<any>(null);
  let { pageRedirect } = useParams();
  const [info] = useSearchParams();
  const page = info.get("page");
  const selector = useSelector((state: any) => state.userReducer);
  const getProducts = async () => {
    let ids = "";
    if (!selector.cart) return ids;
    selector.cart.map((data: any, key: any) => {
      ids += data.quantity ? data.id + "_" + data.quantity : data.id + "-";
    });
    return ids;
  };
  const createAccount = async () => {
    console.log({ email_address, ids: await getProducts(), page });
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      const [res, err] = await fetchFunction({
        url: `${API_URL}${isSignIn ? "login" : "registeruser"}`,
        method: "POST",
        body: { email_address, ids: await getProducts(), page },
      });
      if (err) {
        console.log(err);
        setError(err);
        setLoading(false);
        return;
      }
      if (res) {
        setLoading(false);
        setSuccess("Email has been sent to your email address");
      }
    } catch (e: any) {
      console.log("e", e);
      setError(e.message);
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <Header />
      <ScrollToTop />
      <CatagoryPlace {...{ setSelected, selected }} />
      <div
        style={{
          width: "90%",
          height: 90,
          borderRadius: 10,
          fontSize: "1.3em",
          fontFamily: "Poppins",
          fontWeight: 700,
          letterSpacing: 1,
          marginTop: 40,
          display: "flex",
          color: "#008ECC",
          justifyContent: "center",
          alignItems: "center",
          background: "#F5F5F5",
        }}
      >
        My Acccount
      </div>
      <div
        style={{
          width: "90%",
          marginLeft: "5%",
          display: "flex",
          marginTop: 40,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 20,
          }}
        >
          <div
            className="button"
            style={{
              fontWeight: isSignIn ? "900" : "400",
              background: "white",
              border: "none",
              letterSpacing: 0.5,
              cursor: "pointer",
              textAlign: "left",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              color: "black",
            }}
            onClick={() => {
              setSignIn(true);
            }}
          >
            Login
            <div
              className="underline"
              style={{
                width: 30,
                marginTop: 15,
                height: 2,
                background: isSignIn ? "rgb(0, 142, 204)" : "white",
              }}
            ></div>
          </div>
          <div
            className="hollowbutton"
            style={{
              fontWeight: !isSignIn ? "900" : "400",
              letterSpacing: 0.5,
              textAlign: "left",
              border: "none",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              background: "white",
              cursor: "pointer",
              color: "black",
            }}
            onClick={() => {
              setSignIn(false);
            }}
          >
            Register
            <div
              className="underline"
              style={{
                width: 30,
                marginTop: 15,
                height: 2,
                background: !isSignIn ? "rgb(0, 142, 204)" : "white",
              }}
            ></div>
          </div>
        </div>
        {/* <div className="AuthText">
          This is an passwordless login. You will receive a login link on your
          Email
          <span>
            <br />
            powered with AuthArmor
          </span>
            </div>*/}
        {!success && !error ? null : (
          <div style={{ marginTop: 10, color: error ? "red" : "green" }}>
            {success || error}
          </div>
        )}
        <div
          className="inputcontainer"
          style={{ color: "gray", width: "90%", maxWidth: 400 }}
        >
          Email
          <input
            className="inputItem"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        {loading ? (
          <div className="button" style={{ fontWeight: 400, marginTop: 20 }}>
            <LazyLoadImage
              src={loadingImage}
              style={{ width: 20, height: 20 }}
            />
          </div>
        ) : (
          <div
            onClick={createAccount}
            className="button"
            style={{
              fontWeight: 400,
              marginTop: 40,
              width: "90%",
              maxWidth: 400,
              height: 44,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isSignIn ? "Login" : "Register"}
          </div>
        )}
      </div>
    </div>
  );
}

function Home() {
  const [selected, setSelected] = React.useState("home");
  const [topProduct, setTopProducts] = React.useState("All");

  const Items =
    topProduct === "All"
      ? Products.slice(0, 13)
      : Products.filter((data) => {
          return (
            data.categories === topProduct ||
            data.subCatagories.includes(topProduct)
          );
        });

  return (
    <div className="App">
      <Header />
      <ScrollToTop />
      <CatagoryPlace {...{ setSelected, selected }} />
      <SwiperComponent />
      <FeaturesCarouse />
      <div
        style={{
          width: "90%",
          marginTop: 25,
        }}
      >
        <HeaderTitle firstPart={"Top"} secondPart={"Product"} />
        <CardPlace
          Items={Items}
          setTopProducts={setTopProducts}
          topProduct={topProduct}
        />
      </div>

      <HeaderTitle firstPart={"Shop by"} secondPart={"Category"} />
      <ScrollContainer
        className="shopBy"
        style={{
          display: "flex",
          width: "90%",
          overflowX: "scroll",
          marginTop: 30,
          justifyContent: "space-between",
        }}
      >
        {shopBy.map((data) => {
          return (
            <Link
              to={`/catgory/${data.name}`}
              style={{
                height: 120,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: 80,
                  height: 80,
                  minHeight: 80,
                  borderRadius: 40,

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  backgroundColor: "#e6e6e6",
                }}
              >
                <LazyLoadImage
                  src={data.img}
                  alt=""
                  style={{
                    width: "80%",
                    height: "80%",
                    objectFit: "contain",
                  }}
                />
              </div>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: "0.8em",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  marginTop: 10,
                }}
              >
                {data.name}
              </p>
            </Link>
          );
        })}
      </ScrollContainer>
      <HeaderTitle firstPart={"Top"} secondPart={"Electoronics Brands"} />
      <OfferCarousel />
      <HeaderTitle firstPart={"Recently"} secondPart={"Viewed"} />
      <RelatedProduct />
      <div className="WihtePsace" />
    </div>
  );
}
const Validate = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any>(null);
  const dispatch = useDispatch<any>();
  const [success, setSuccess] = React.useState(null);
  const navigate = useNavigate();
  const [info] = useSearchParams();
  const auth_validation_token = info.get("auth_validation_token");
  const auth_request_id = info.get("auth_request_id");
  const ids = info.get("ids");
  const page = info.get("page");
  const registration_validation_token = info.get(
    "registration_validation_token"
  );

  const [isSignIn, setSignIn] = React.useState<any>(null);
  React.useEffect(() => {
    if (auth_validation_token && auth_request_id) {
      setSignIn(true);
    }
    if (registration_validation_token) {
      setSignIn(false);
    }
  }, []);
  React.useEffect(() => {
    if (isSignIn === null) return;

    createAccount();
  }, [isSignIn]);
  const getProducts = async () => {
    if (!ids && !ids?.includes("-")) return;
    const products: any = [];
    ids?.split("-").map((data: any) => {
      if (data !== "" && data.replace(/\s/g, "") !== "") {
        const productId = data.includes("_") ? data?.split("_")[0] : data;
        const Counter = data.includes("_") ? data?.split("_")[1] : 0;

        const product: any = Products.find(
          (datas) => String(datas.id) === productId
        );
        if (product) {
          if (Counter) {
            product.quantity = Counter;
          }
          products.push(product);
        }
      }
    });
    await dispatch(CopyCartDisptact(products));
    return products;
  };
  const CallApi = async (token: any, response: any) => {
    if (!token) {
      return;
    }
    const [res, err] = await fetchFunction({
      url: `${API_URL}addtousercard`,
      method: "POST",
      body: { product_ids: response },
      auth: token,
    });
    if (err) {
      return;
    }
  };
  const createAccount = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await getProducts();
      const [res, err] = await fetchFunction({
        url: `${API_URL}${isSignIn ? "verifyLogin" : "verify"}`,
        method: "POST",
        body: {
          auth_validation_token,
          auth_request_id,
          registration_validation_token,
        },
      });
      console.log(res, err);
      if (err) {
        console.log(err);
        setError(err);
        setLoading(false);
        return;
      }
      if (res && res.token) {
        setLoading(false);
        localStorage.setItem("ecommerce-token", res.token);
        await CallApi(res.token, response);
        dispatch(setTokenAction(res.token));
        dispatch(setUserAction(res));
        console.log(page);
        navigate(page ? "/" + page : "/");
        console.log(res);
      } else {
        setError("Something went wrong");
        setLoading(false);
      }
    } catch (e: any) {
      setError(e.message);
      setLoading(false);
    }
  };

  return (
    <div
      className="App"
      style={{
        width: "100vw",
        height: "100vw",
      }}
    >
      <h1>{error}</h1>
      <LazyLoadImage src={loadingImage} style={{ width: 50, height: 50 }} />
    </div>
  );
};
const CartProduct = () => {
  const selector = useSelector((state: any) => state.userReducer);
  const navigate = useNavigate();
  const dipatch = useDispatch<any>();
  const dispatch = useDispatch<any>();
  const [selected, setSelected] = React.useState("home");
  const [loadingCikout, SetloadingCikout] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [subTotal, setsubTotal] = React.useState(
    selector.cart
      ? selector.cart
          .map((data: any) =>
            Math.round(
              (data.price - Math.round(data.price * data.sale) / 100) *
                data.quantity
            )
          )
          .reduce((partialSum: any, a: any) => partialSum + a, 0)
      : "0"
  );
  React.useEffect(() => {
    console.log("selector.cart");
    setsubTotal(
      selector.cart
        ? selector.cart
            .map((data: any) =>
              Math.round(
                (data.price - Math.round(data.price * data.sale) / 100) *
                  data.quantity
              )
            )
            .reduce((partialSum: any, a: any) => partialSum + a, 0)
        : "0"
    );
  }, [selector.cart]);

  const CallApi = async (response: any, removeAll: any) => {
    const token = localStorage.getItem("ecommerce-token");
    if (!token) {
      return;
    }
    const [res, err] = await fetchFunction({
      url: `${API_URL}addtousercard`,
      method: "POST",
      body: { product_ids: { ...response, ...{ removeAll } } },
      auth: token,
    });
    if (err) {
      return;
    }
  };

  return (
    <div className="App">
      <Header />
      <CatagoryPlace {...{ setSelected, selected }} />
      <ScrollToTop />
      <div
        style={{
          width: "90%",
          height: 90,
          borderRadius: 10,
          fontSize: "1.3em",
          fontFamily: "Poppins",
          fontWeight: 700,
          letterSpacing: 1,
          marginTop: 40,
          display: "flex",
          color: "#008ECC",
          justifyContent: "center",
          alignItems: "center",
          background: "#F5F5F5",
        }}
      >
        {selector.cart && selector.cart.length} Product in cart
      </div>
      <div
        style={{
          width: "95%",
          marginLeft: "5%",
          display: "flex",
          flexWrap: "wrap",
          marginTop: 40,
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 20,
        }}
      >
        {!selector.cart || (selector.cart && selector.cart.length < 1) ? (
          <h2>Cart is Empty</h2>
        ) : null}
        {getWindowDimensions().width > 600 &&
        selector.cart &&
        selector.cart.length > 0 ? (
          <div
            className="card-cart"
            style={{
              border: "1px solid lightgray",
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              paddingRight: 100,
              height: 80,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="card-img-cart"
              style={{
                height: "auto",
              }}
            >
              <h3 className="special" style={{ marginTop: 10 }}>
                Image
              </h3>
            </div>
            <div className="card-body-cart">
              <div
                className="card-title"
                style={{ width: "100%", maxWidth: 250 }}
              >
                <h3 className="special">Title</h3>
              </div>{" "}
              <div
                className="product-Row-Counter"
                style={{
                  height: "auto",
                  marginTop: 5,
                  width: "100%",
                  background: "white",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  minWidth: 75,
                  maxWidth: 120,
                }}
              >
                <h3 className="special">Amount</h3>
              </div>
              <div
                className="card-price"
                style={{ width: "100%", maxWidth: 80 }}
              >
                <h3>Price </h3>
              </div>
              <div
                className="card-price"
                style={{ width: "100%", maxWidth: 80 }}
              >
                <h3>Total</h3>
              </div>
              <div style={{ background: "white", cursor: "pointer" }}>
                <h3 className="special"></h3>
              </div>
            </div>
          </div>
        ) : null}
        {selector.cart && selector.cart.length > 0 ? (
          <div
            style={{
              width: "95%",
              border: "1px solid lightgray",
              borderTop: 0,
              marginTop: -20,
              paddingBottom: 30,
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
            }}
          >
            {selector.cart &&
              selector.cart.map((data: any) => {
                if (getWindowDimensions().width > 600) {
                  return <CardItem product={data} />;
                } else {
                  return <SmallCardChat multiPrice={true} product={data} />;
                }
              })}
          </div>
        ) : null}
      </div>
      <div
        style={{
          width: "90%",
          marginTop: 40,
          border: "0.5px solid lightgray",
        }}
      ></div>
      <div
        style={{
          width: "100%",
        }}
      ></div>
      {0 ? (
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            justifyContent: "flex-start",
            marginTop: 30,
            fontFamily: "Poppins",
          }}
        >
          Do you have a question?
          <div
            className="inputcontainer"
            style={{
              color: "black",
              width: "90%",
              display: "flex",
              gap: 10,
              marginTop: 0,
              maxWidth: 400,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input className="inputItem" onChange={(e) => {}} />
            <div
              className="button"
              style={{
                background: 0 ? "white" : "#008ECC",
                color: 0 ? "#008ECC" : "white",
                border: 0 ? "1px solid #008ECC" : "none",
                fontSize: "0.8em",
                display: "flex",
                width: 70,
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 5,
                borderRadius: 2,
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(
                  setUpdate({ status: true, message: "this is a demo site" })
                );
              }}
            >
              Apply
            </div>
          </div>
        </div>
      ) : null}
      <div
        style={{ width: "90%", display: "flex", justifyContent: "flex-end" }}
      >
        {selector.cart && selector.cart.length > 0 ? (
          <div
            style={{
              width: "100%",
              maxWidth: 400,
              marginTop: 20,
            }}
          >
            <div className="rowTotal">
              <div className="rowItem">Sub-total</div>
              <div className="rowItem">${subTotal}</div>
            </div>
            <div className="rowTotal">
              <div className="rowItem">Tax</div>
              <div className="rowItem">${subTotal * 0.5}</div>
            </div>
            <div className="rowTotal">
              <div className="rowItem">Shipping</div>
              <div className="rowItem">$5</div>
            </div>
            <div className="rowTotal">
              <div className="rowItem">Total</div>
              <div className="rowItem">{subTotal + subTotal * 0.5 + 5}</div>
            </div>
            <div
              style={{
                display: "flex",
                gap: 20,
                marginTop: 20,
              }}
            >
              <div
                className="button"
                style={{
                  background: 0 ? "white" : "#008ECC",
                  color: 0 ? "#008ECC" : "white",
                  border: 0 ? "1px solid #008ECC" : "none",
                  fontSize: "0.8em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // setSignIn(true);
                  if (selector.user.user_id) {
                    dispatch(setOrderAction(selector.cart));
                    dispatch(CopyCartDisptact([]));
                    CallApi({}, "removeAll");

                    navigate("/confirmPurchases", {
                      state: { total: subTotal + subTotal * 0.5 + 5 },
                    });
                    return;
                  }

                  navigate("/login?page=cart");
                  return;
                }}
              >
                Checkout
              </div>
              <Link
                to="/"
                className="hollowbutton"
                style={{
                  background: 1 ? "white" : "#008ECC",
                  color: 1 ? "#008ECC" : "white",
                  fontSize: "0.8em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  //  setSignIn(false);
                }}
              >
                Contiune Shopping
              </Link>
            </div>
          </div>
        ) : null}
      </div>
      <div className="WihtePsace" />
    </div>
  );
};

const Profile = () => {
  const selector = useSelector((state: any) => state.userReducer);
  const [selected, setSelected] = React.useState("home");
  const [profiles, setProfile] = React.useState<any>(true);
  const UserInfo = ({ profiles }: any) => {
    if (!profiles) {
      return null;
    }
    return (
      <>
        <div className="dataRow">
          Email : <span>{selector.user.email || selector.user.username}</span>
        </div>
        <div className="dataRow">
          usern name : <span>{selector.user.username}</span>
        </div>
        <div
          className="button"
          style={{
            marginTop: 20,
          }}
          onClick={() => {
            localStorage.removeItem("ecommerce-token");
            window.location.href = "/";
          }}
        >
          Log out
        </div>
      </>
    );
  };
  return (
    <div className="App">
      <Header />
      <CatagoryPlace {...{ setSelected, selected }} />
      <ScrollToTop />
      <div
        style={{
          width: "90%",
          height: 90,
          borderRadius: 10,
          fontSize: "1.3em",
          fontFamily: "Poppins",
          fontWeight: 700,
          letterSpacing: 1,
          marginTop: 40,
          display: "flex",
          color: "#008ECC",
          justifyContent: "center",
          alignItems: "center",
          background: "#F5F5F5",
        }}
      >
        Profile
      </div>
      <div
        style={{
          width: "90%",
          marginLeft: "5%",
          display: "flex",
          marginTop: 40,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="RightRow">
          <UserInfo profiles={profiles} />
        </div>
      </div>
    </div>
  );
};
const Footer = () => {
  return (
    <div className="footer">
      <LazyLoadImage src={Mask} alt="logo" className="Bubble" />
      <div className="footer-container">
        <div className="firstRow">
          <LazyLoadImage src={logowhite} alt="logo" className="bubbleLogo" />
          <h2>Contact us</h2>
          <div className="bubbleImageCOntainer">
            <LazyLoadImage src={whatsapp} alt="logo" className="itemRowIcon" />
            <LazyLoadImage src={callus} alt="logo" className="itemRowIcon" />
          </div>
        </div>
        <div className="firstRow">
          <h2>Find it fast</h2>
          <ul className="FindIT">
            {TopCatagories.map((data, key) => {
              return (
                <li className="FindITData ">
                  <Link to={`/catgory/${data}/`}>{data}</Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="firstRow">
          <LazyLoadImage
            src={information}
            alt="logo"
            className="itemRowIcon FinalThirdRow"
          />
        </div>
      </div>
      <div className="bubbleFinis">
        This is a demo website for a ecommerce website to showcase AuthArmor
        authentication and authorization services.
      </div>
    </div>
  );
};
function Confirm() {
  const selector = useSelector((state: any) => state.userReducer);
  const [selected, setSelected] = React.useState("home");
  const [profiles, setProfile] = React.useState<any>(true);
  const { state } = useLocation();
  const { total } = state ? state : { total: 0 };
  return (
    <div className="App">
      <Header />
      <CatagoryPlace {...{ setSelected, selected }} />
      <ScrollToTop />
      <div
        style={{
          width: "90%",
          height: 90,
          borderRadius: 10,
          fontSize: "1.3em",
          fontFamily: "Poppins",
          fontWeight: 700,
          letterSpacing: 1,
          marginTop: 40,
          display: "flex",
          color: "#008ECC",
          justifyContent: "center",
          alignItems: "center",
          background: "#F5F5F5",
        }}
      >
        Confirmation
      </div>
      <div
        style={{
          width: "95%",
          display: "flex",
          flexWrap: "wrap",
          marginTop: 40,
          justifyContent: "center",
          fontFamily: "Poppins",
          fontSize: "0.8em",
          alignItems: "center",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <div>
          <img
            src={Z}
            style={{
              width: 100,
              height: 100,
            }}
          />
        </div>
        <h3
          style={{
            fontFamily: "Poppins",
            fontWeight: 500,
            letterSpacing: 1,
            color: "gray",
            marginTop: -10,
            fontSize: "1.2em",
          }}
        >
          Order placed!
        </h3>
        <h3
          style={{
            fontFamily: "Poppins",
            fontWeight: 700,
            letterSpacing: 1,
            fontSize: "1.5em",
            marginTop: -20,
          }}
        >
          ${total}
        </h3>
        <div
          style={{
            width: "90%",
            maxWidth: 400,
            height: 0.3,
            marginTop: 20,
            background: "lightgray",
          }}
        />
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            gap: 10,
            justifyContent: "center",
            marginLeft: "5%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "90%",
              maxWidth: 400,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "50%", textAlign: "left", color: "gray" }}>
              Ref Number
            </div>
            <div style={{ width: "50%", fontWeight: 600 }}>
              0000
              {
                //generate random number starting with 4 zeros
                Math.floor(2000 + Math.random() * 10000)
              }
              {Math.floor(2000 + Math.random() * 10000)}
            </div>
          </div>
          <div
            style={{
              width: "90%",
              maxWidth: 400,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "50%", textAlign: "left", color: "gray" }}>
              Payment Time
            </div>
            <div style={{ width: "50%", fontWeight: 600 }}>
              {new Date().getDay() >= 10
                ? new Date().getDay() + 1
                : "0" + new Date().getDay()}
              -
              {new Date().getMonth() >= 10
                ? new Date().getMonth()
                : "0" + new Date().getMonth()}
              -{new Date().getFullYear()},{" "}
              {new Date().getHours() >= 10
                ? new Date().getHours()
                : "0" + new Date().getHours()}
              :
              {new Date().getMinutes() >= 10
                ? new Date().getMinutes()
                : "0" + new Date().getMinutes()}
              : :
              {new Date().getSeconds() >= 10
                ? new Date().getSeconds()
                : "0" + new Date().getSeconds()}
            </div>
          </div>
          <div
            style={{
              width: "90%",
              maxWidth: 400,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "50%", textAlign: "left", color: "gray" }}>
              Payment Type
            </div>
            <div style={{ width: "50%", fontWeight: 600 }}>Card</div>
          </div>
          <div
            style={{
              width: "90%",
              maxWidth: 400,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "50%", textAlign: "left", color: "gray" }}>
              Sender Name
            </div>
            <div style={{ width: "50%", fontWeight: 600 }}>John Doe</div>
          </div>
          <div
            style={{
              width: "95%",
              marginRight: "2.5%",
              maxWidth: 400,
              marginTop: 10,
              marginBottom: 10,
              height: 1,
              borderStyle: "dashed",
              borderWidth: 1,
              borderColor: "lightgray",
            }}
          ></div>

          <div
            style={{
              width: "90%",
              maxWidth: 400,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "50%", textAlign: "left", color: "gray" }}>
              Total
            </div>
            <div style={{ width: "50%", fontWeight: 600 }}>${total}</div>
          </div>
        </div>
      </div>
      <div>
        <Link
          to={"/"}
          className="button"
          style={{
            fontWeight: 400,
            marginTop: 40,
            height: 44,
            display: "flex",
            marginBottom: 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Return to home Page
        </Link>
      </div>
    </div>
  );
}
function App() {
  const selector = useSelector((state: any) => state.userReducer);
  const dispatch = useDispatch<any>();
  React.useEffect(() => {
    if (selector.notificationUpdate.message) {
      setTimeout(() => {
        dispatch(setUpdate({}));
      }, 1500);
    }
  }, [selector]);

  const checkIfUserIsSingIn = async () => {
    const token = localStorage.getItem("ecommerce-token");
    if (token) {
      const [res, err] = await fetchFunction({
        url: `${API_URL}getuser`,
        method: "POST",
        auth: token,
      });
      if (err) {
        return;
      }

      dispatch(setUserAction(res.data[0]));
      const prod: any = [];
      res.data[0].items_in_cart.map((item: any) => {
        if (!item.id) {
        } else {
          {
            const index = prod.findIndex((i: any) => i.id === item.id);

            if (index !== -1) {
              prod[index].quantity =
                (prod[index].quantity ? prod[index].quantity : 0) + 1;
            } else {
              prod.push(item);
            }
          }
        }
      });

      dispatch(CopyCartDisptact(prod));
    }
  };

  React.useEffect(() => {
    console.log("hello");
    checkIfUserIsSingIn();
  }, []);
  return (
    <BrowserRouter>
      {selector.notificationUpdate.message ? (
        <div
          className="animate__animated animate__bounce"
          style={{
            position: "fixed",

            width: "100%",
            top: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 4243,
          }}
        >
          <div
            style={{
              padding: 15,
              paddingLeft: 30,
              borderRadius: 15,
              paddingRight: 30,
              gap: 10,
              fontFamily: "Poppins",
              color: "gray",
              fontWeight: 400,
              letterSpacing: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 0px 5px 5px lightgray",
              background: "white",
              width: "fit-content",
            }}
          >
            <LazyLoadImage
              src={selector.notificationUpdate.status ? SuccussPng : ErrorPng}
              style={{ width: 25, height: 25 }}
            />
            <div>{selector.notificationUpdate.message}</div>
          </div>
        </div>
      ) : null}
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="/home" element={<Home />} />
        </Route>
        <Route path="/product/:id" element={<Produc />} />
        <Route path="/catgory/:id" element={<Catagory />} />
        <Route path="/login" element={<Login />} />
        <Route path="/validate" element={<Validate />} />
        <Route path="/cart" element={<CartProduct />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/checkout" element={<Catagory />} />
        <Route path="/confirmPurchases" element={<Confirm />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
/*
 */
export default App;
