import React from "react";
import styles from "./styles.module.css";
import { ReactSVG } from "react-svg";
import searchsvg from "../../assets/svg/search.svg";
import { Products } from "../../data";
import { Link, useNavigate } from "react-router-dom";

export default function Search() {
  const [search, setSearch] = React.useState<string>("");
  const navigate = useNavigate();

  return (
    <div className={styles.search}>
      <ReactSVG
        src={searchsvg}
        style={{
          height: 17,
          width: 17,
        }}
        onError={(error) => {
          console.error(error);
        }}
      />
      <input
        className={styles.searchText}
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        placeholder="Search phones, computers and more"
      />
      {search !== "" ? (
        <div
          style={{
            position: "absolute",
            top: 50,
            background: "#F3F9FB",
            left: 0,
            zIndex: 232,
            borderRadius: 4,
            border: "0.6px solid lightgray",
            width: "100%",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 2,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            {Products.filter(
              (product: any) =>
                (product.title.toUpperCase().includes(search.toUpperCase()) &&
                  search.toUpperCase() !== "") ||
                product.categories
                  .toUpperCase()
                  .includes(search.toUpperCase()) ||
                product.subCatagories
                  .map((data: any) => data.toUpperCase())
                  .includes(search.toUpperCase())
            ).map((product: any) => {
              return (
                <div
                  onClick={() => {
                    setSearch("");
                    navigate(`/product/${product.id}`);
                  }}
                  style={{
                    fontSize: "0.8em",
                    cursor: "pointer",
                    fontFamily: "Poppins",
                    color: "black",

                    letterSpacing: 0.5,
                    margin: 3,
                    marginTop: 9,
                    paddingBottom: 2,
                  }}
                >
                  {product.title}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
