import { useRef } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import homePage1 from "../../assets/images/homePage1.jpg";
import homePage2 from "../../assets/images/HomePage2.jpg";
import homePage3 from "../../assets/images/HomePage3.jpg";
import arrowDown from "../../assets/svg/arrowDown.svg";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const LeftAndRight = ({
  left,
  onClick,
}: {
  left: boolean;
  onClick?: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        ...{
          height: "100%",
          zIndex: 3,
          width: "10vw",
          borderRadius: 25,

          position: "absolute",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        ...(left
          ? { left: -getWindowDimensions().width * 0.05 }
          : { right: -getWindowDimensions().width * 0.05 }),
      }}
    >
      <div
        style={{
          height: "10vw",
          maxWidth: 75,
          maxHeight: 75,
          width: "10vw",
          borderRadius: "5vw",
          display: "flex",
          backgroundColor: "white",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "80%",
            width: "80%",
            borderRadius: "40%",
            backgroundColor: "#F3F9FB",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactSVG
            src={arrowDown}
            style={{
              height: 20,
              width: 20,
              transform: left ? "rotate(90deg)" : "rotate(-90deg)",
            }}
          />
        </div>
      </div>
    </div>
  );
};
const SwiperComponent = () => {
  const swiperRef = useRef<any>(null);
  return (
    <div
      style={{
        width: "90%",
        position: "relative",
        display: "flex",
        marginTop: 20,
      }}
    >
      <LeftAndRight
        left={true}
        onClick={() => {
          swiperRef.current.swiper.slidePrev();
        }}
      />
      <LeftAndRight
        left={false}
        onClick={() => {
          swiperRef.current.swiper.slideNext();
        }}
      />
      <Swiper
        spaceBetween={50}
        ref={swiperRef}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        style={{ width: "100%" }}
      >
        <SwiperSlide style={{ position: "relative", display: "flex" }}>
          <Link to={"/product/9872"} style={{ width: "100%" }}>
            <img
              src={homePage1}
              style={{
                ...{
                  width: "100%",
                  borderRadius: 15,
                },
                ...(getWindowDimensions().width > 600
                  ? { height: "30vw", objectFit: "cover" }
                  : {}),
              }}
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide style={{ position: "relative", display: "flex" }}>
          {" "}
          <Link to={"/product/9872"} style={{ width: "100%" }}>
            <img
              src={homePage2}
              style={{
                ...{
                  width: "100%",
                  borderRadius: 15,
                },
                ...(getWindowDimensions().width > 600
                  ? { height: "30vw", objectFit: "cover" }
                  : {}),
              }}
            />{" "}
          </Link>
        </SwiperSlide>
        <SwiperSlide style={{ position: "relative", display: "flex" }}>
          {" "}
          <Link to={"/product/9984"} style={{ width: "100%" }}>
            <img
              src={homePage3}
              style={{
                ...{
                  width: "100%",
                  borderRadius: 15,
                },
                ...(getWindowDimensions().width > 600
                  ? { height: "30vw", objectFit: "cover" }
                  : {}),
              }}
            />
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SwiperComponent;
