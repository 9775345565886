import {
  CopyCart,
  setCart,
  setNotifcationUpdate,
  setOrder,
  setToken,
  setUser,
} from "../reducers/userReducer";

export const setUserAction = (state: any) => {
  return (dispatch: any) => {
    dispatch(setUser(state));
  };
};
export const setUpdate = (state: any) => {
  return (dispatch: any) => {
    dispatch(setNotifcationUpdate(state));
  };
};

export const setTokenAction = (state: any) => {
  return (dispatch: any) => {
    dispatch(setToken(state));
  };
};
export const setOrderAction = (state: any) => {
  return (dispatch: any) => {
    dispatch(setOrder(state));
  };
};
export const CopyCartDisptact = (state: any) => {
  //CopyCart
  return (dispatch: any) => {
    dispatch(CopyCart(state));
  };
};
export const setCartAction = (state: any) => {
  return (dispatch: any) => {
    dispatch(setCart(state));
  };
};
