import { ReactSVG } from "react-svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Group from "../../assets/images/Group.png";
import Group2 from "../../assets/images/Group2.png";
import Group3 from "../../assets/images/Group3.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Group4 from "../../assets/images/Group4.png";
import Group5 from "../../assets/images/Group5.png";
import Group6 from "../../assets/images/Group6.png";
import arrowDown from "../../assets/svg/arrowDown.svg";
import { Link } from "react-router-dom";
const carouselImages = [Group, Group2, Group3, Group4, Group5, Group6];
const carouselLinks = [
  "/product/5",
  "/product/1",
  "/product/98773",
  "/product/198",
  "/product/82",
  "/product/9872",
];
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const LeftAndRight = ({
  left,
  onClick,
}: {
  left: boolean;
  onClick?: () => void;
}) => {
  return (
    <div
      style={{
        ...{
          height: "100%",
          zIndex: 3,
          width: "10vw",
          borderRadius: 25,

          position: "absolute",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        ...(left
          ? { left: -getWindowDimensions().width * 0.05 }
          : { right: -getWindowDimensions().width * 0.05 }),
      }}
    >
      <div
        style={{
          height: "10vw",
          maxWidth: 75,
          maxHeight: 75,
          width: "10vw",
          borderRadius: "5vw",
          display: "flex",
          backgroundColor: "white",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "80%",
            width: "80%",
            borderRadius: "40%",
            backgroundColor: "#F3F9FB",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactSVG
            src={arrowDown}
            style={{
              height: 20,
              width: 20,
              transform: left ? "rotate(90deg)" : "rotate(-90deg)",
            }}
          />
        </div>
      </div>
    </div>
  );
};
const SwiperComponent = () => {
  return (
    <div
      style={{
        width: "90%",
        position: "relative",
        display: "flex",
        marginTop: 40,
      }}
    >
      <Swiper
        spaceBetween={50}
        slidesPerView={getWindowDimensions().width < 600 ? 1 : 3}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        navigation
        pagination={{ clickable: true }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        style={{ width: "100%" }}
      >
        {carouselImages.map((data, index) => {
          return (
            <SwiperSlide
              style={{
                width: getWindowDimensions().width < 600 ? "100%" : 300,
                height: getWindowDimensions().width < 600 ? "60vw" : 230,
              }}
            >
              <Link style={{ width: 100 }} to={carouselLinks[index]}>
                <LazyLoadImage
                  src={data}
                  style={{
                    width: "100%",
                    borderRadius: 15,
                    objectFit: "contain",
                  }}
                />
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default SwiperComponent;
