const INTIAL_STATE = {
  cart: null as any,
  order: [] as any,
  token: "",
  user: {},
  notificationUpdate: {},
};
const reducer = (state = INTIAL_STATE, action: any) => {
  switch (action.type) {
    case "SET_NOTIFICATION_UPDATE":
      return {
        ...state,
        notificationUpdate: action.payload,
      };

    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "SET_ORDER":
      return {
        ...state,
        order: action.payload,
      };
    case "COPY_CART":
      return {
        ...state,
        cart: action.payload,
      };
    case "SET_CART":
      const TheCarts = [...(state.cart || [])];
      if (action.payload.add) {
        const theItemIndex = TheCarts.findIndex(
          (item: any) => item.id === action.payload.id
        );
        if (theItemIndex === -1) {
          TheCarts.push(action.payload);
        } else {
          TheCarts[theItemIndex].quantity += 1;
        }
      } else {
        const theItemIndex = TheCarts.findIndex(
          (item: any) => item.id === action.payload.id
        );
        if (theItemIndex === -1) {
          return {
            ...state,
            cart: TheCarts,
          };
        }
        console.log("theItemIndex", TheCarts[theItemIndex]);
        if (TheCarts[theItemIndex].quantity > 1) {
          TheCarts[theItemIndex].quantity -= 1;
          return {
            ...state,
            cart: TheCarts,
          };
        }
        TheCarts.splice(theItemIndex, 1);
      }
      return {
        ...state,
        cart: TheCarts,
      };
    default:
      return state;
  }
};
export const setNotifcationUpdate = (state: any) => {
  return {
    type: "SET_NOTIFICATION_UPDATE",
    payload: state,
  };
};
export const setUser = (state: any) => {
  return {
    type: "SET_USER",
    payload: state,
  };
};
export const setToken = (state: any) => {
  return {
    type: "SET_TOKEN",
    payload: state,
  };
};
export const setOrder = (state: any) => {
  return {
    type: "SET_ORDER",
    payload: state,
  };
};

export const CopyCart = (state: any) => {
  return {
    type: "COPY_CART",
    payload: state,
  };
};
export const setCart = (state: any) => {
  return {
    type: "SET_CART",
    payload: state,
  };
};
export default reducer;
