type method =
  | "GET"
  | "POST"
  | "PUT"
  | "DELETE"
  | "PATCH"
  | "OPTIONS"
  | "HEAD"
  | "TRACE"
  | "CONNECT"
  | "LINK"
  | "UNLINK"
  | "PURGE"
  | "LOCK"
  | "UNLOCK"
  | "PROPFIND"
  | "VIEW"
  | undefined;
const fetchFunction = async ({
  url,
  method,
  body = undefined,
  auth = undefined,
}: {
  url: string;
  method: method;
  auth?: string | undefined;
  body?: any;
}) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 9000);

  try {
    const response = await fetch(url, {
      ...{
        signal: controller.signal,
        method: method,
        headers: {
          ...{
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          ...(auth ? { Authorization: `Bearer ${auth}` } : {}),
        },
      },
      ...(body ? { body: JSON.stringify(body) } : {}),
    });
    const json = await response.json();
    if (response.status !== 200) {
      throw new Error(json.message);
    }
    return [json, null];
  } catch (e: any) {
    return [null, e.message];
  }
};
export default fetchFunction;
